import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    .w-100 {
        width: 100%;
    }
    .m-w-100 {
        max-width: 100%;
    }
    .m-w-80 {
        max-width: 80%;
    }
    .m-w-60 {
      max-width: 60%;
  }
    .body-text-link {
        text-decoration: none;
    }
    .greenhouse-richtext span {
        font-weight: 300 !important;
    }
    .spaced-grid-wrapper {
        height: auto;
        overflow: hidden;
    }
    #grnhse_iframe {
        background-color: #fff;
    }
    #grnhse_iframe #app_body {
        padding: 0px;
    }
    .headroom {
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
      }
      .headroom--unfixed {
        position: relative;
        transform: translateY(0);
      }
      .headroom--scrolled {
        transition: transform 200ms ease-in-out;
      }
      .headroom--unpinned {
        position: fixed;
        transform: translateY(-100%);
      }
      .headroom--pinned {
        position: fixed;
        transform: translateY(0%);
      }
      .text-align-left {
        text-align: left;
      }
      .player-wrapper {
        position: relative;
        padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
      }
      .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }
      .banner-content-wrapper img {
        max-width: 100px;
      }
`;
